<template>
  <div>
    <div
      ref="mainReport"
      class="mainContainer m-0 px-3 py-4"
      style="position: relative"
    >
      <div class="container">
        <div class="row">
          <div class="col-8">
            <p class="blue-text fs-5 fw-bold m-0">
              REPORTE INDIVIDUAL DE EJECUCIÓN
            </p>
            <p class="blue-text fs-6 m-0">Comprobante de actividad realizada</p>
          </div>
          <div class="col-4">
            <div class="d-flex justify-content-end">
              <p class="blue-text border rounded-pill blue-border py-1 px-3">
                Fecha de la Actividad:
                <strong>{{ summary.activityDate }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-3 rounded blue-border">
        <div class="row">
          <div class="blue-bg rounded-top col">
            <p class="text-white m-1 text-center fw-bold">
              I. Información de la Empresa
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-9 blue-border">
            <p class="m-1">
              Nombre o Razón Social: <strong>{{ summary.userName }}</strong>
            </p>
          </div>
          <div class="col-3 blue-border">
            <p class="m-1">
              NIT o CC: <strong>{{ summary.userID }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 blue-border">
            <p class="m-1">
              Dirección: <strong>{{ summary.branchAddress }}</strong>
            </p>
          </div>
          <div class="col-3 blue-border">
            <p class="m-1">
              Teléfono: <strong>{{ summary.branchPhone }}</strong>
            </p>
          </div>
          <div class="col-3 blue-border">
            <p class="m-1">
              Celular: <strong>{{ summary.companyMobile }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 blue-border">
            <p class="m-1">
              Contacto: <strong>{{ summary.companyContact }}</strong>
            </p>
          </div>
          <div class="col-6 blue-border">
            <p class="m-1">Cargo: <strong>Representante Legal</strong></p>
          </div>
        </div>
        <div class="row">
          <div class="col-7 blue-border">
            <p class="m-1">
              Email: <strong>{{ this.summary.companyEmail }}</strong>
            </p>
          </div>
          <div class="col-5 blue-border">
            <p class="m-1">
              Ciudad: <strong>{{ summary.cityName }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-1 rounded blue-border">
        <div class="row">
          <div class="col-2 blue-border">
            <p class="m-1">
              Hora de Inicio: <br /><strong>{{ summary.startTime }}</strong>
            </p>
          </div>
          <div class="col-2 blue-border">
            <p class="m-1">
              Hora Final: <br /><strong>{{ summary.endTime }}</strong>
            </p>
          </div>
          <div class="col-2 blue-border">
            <p class="m-1">
              Duración: <br /><strong>{{ summary.totalTime }} Horas</strong>
            </p>
          </div>
          <div class="col-6 blue-border">
            <p class="m-1">
              Objetivo: <strong>{{ summary.targetName }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container mt-2 rounded blue-border">
        <div class="row">
          <div class="blue-bg rounded-top col">
            <p class="text-white m-1 text-center fw-bold">
              II. Actividad Desarrollada
            </p>
          </div>
        </div>
        <div class="row">
          <div class="activity-section col-12 blue-border">
            <p class="m-1">{{ summary.activity }}</p>
          </div>
        </div>
      </div>
      <div class="container mt-1 rounded blue-border">
        <div class="row">
          <div class="col-12 blue-border">
            <p class="m-1">
              Estándar Intervenido: <strong>{{ summary.standardName }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="moreInfo">
        <p>
          Las empresas y sus equipos gerenciales deben estar preparados para los
          retos en seguridad laboral que surgen día a día en el país, adoptando
          medidas que les permitan anticiparse y aprovechar la mejora continua
          en sus procesos.
        </p>
        <p>
          Mediante nuestros servicios de “Consultoría” ayudamos a nuestros
          clientes a alcanzar sus objetivos de SST, comprendiendo sus
          necesidades y proporcionando soluciones prácticas y efectivas,
          trabajando colaborativamente y garantizando respuestas funcionales
          rápidas.
        </p>
        <p>
          Somos una comunidad de solucionadores, que combina el ingenio humano,
          la experiencia y la innovación tecnológica para ofrecer resultados
          sostenibles y generar confianza en sus colaboradores, proporcionamos
          enfoques innovadores y exitosos en las diferentes interacciones
          consultivas con nuestros clientes, reforzando e incrementando cada día
          sus experiencias y conocimientos a través de nuestros procesos de
          intervención con enfoque de formación.
        </p>
      </div>
      <div
        class="container mt-2 rounded blue-border"
        style="width: 280px; right: 1rem; position: absolute"
      >
        <div class="row">
          <div class="blue-bg rounded-top col">
            <p class="text-white m-1 text-center fw-bold">
              III. Evidencias Fotográficas
            </p>
          </div>
        </div>
        <div class="row">
          <div class="photo-section blue-border">
            <canvas ref="canvas"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import { prettyDate, titleCase, prettyID } from "@/modules/tools";
import Swal from "sweetalert2";
import QRCode from "qrcode";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  name: "Plain Summary Ejecution Report",
  data() {
    return {
      summary: {}
    };
  },
  props: ["runNow"],
  watch: {
    runNow: function(value) {
      if (value) {
        this.run();
      }
    }
  },
  methods: {
    async run() {
      const doc = new jsPDF("p", "pt", "letter");
      Swal.fire({
        icon: "warning",
        title: "Generando Reporte",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      const summary = await store.getData("summary", this.runNow);
      const target = await store.getData("target", summary.target);
      const standard = await store.getData("standard", summary.standard);
      const branch = await store.getData("branch", summary.branch);
      const company = await store.getData("company", branch.company);
      const city = await store.getData("city", branch.city);
      this.summary = summary;
      this.summary.targetName = target.name;
      this.summary.standardName = standard.name;
      this.summary.activityDate = await prettyDate(summary.endDate);
      this.summary.branchAddress = branch.address;
      this.summary.branchPhone = branch.phone;
      this.summary.companyContact = company.legalRepresentative;
      this.summary.companyEmail = company.representativeEmail;
      this.summary.companyMobile = company.representativeMobile;
      this.summary.cityName = await titleCase(city.name);
      this.summary.userName = localStorage.getItem("user.name");
      this.summary.userID = await prettyID(localStorage.getItem("user.ID"));

      const fHour = new Date("August 19, 1975 " + summary.startTime).getTime();
      const eHour = new Date("August 19, 1975 " + summary.endTime).getTime();
      let totalTime = (eHour - fHour) / 1000;
      totalTime = totalTime / 60;
      totalTime = totalTime / 60;
      this.summary.totalTime = totalTime.toFixed(1);

      const URL = store.baseURL + "/optima/publicEvidences/" + this.summary._id;
      QRCode.toCanvas(
        this.$refs.canvas,
        URL,
        { errorCorrectionLevel: "H", width: 275, height: 275 },
        function(error) {
          if (error) console.error(error);
          console.log("success!");
        }
      );
      doc.html(this.$refs.mainReport, {
        html2canvas: {
          scale: 0.61,
          useCORS: true
        },
        callback: function(doc) {
          doc.save("Reporte de Ejecución.pdf");
          Swal.fire({
            icon: "success",
            title: "Reporte Generado",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="css">
.moreInfo {
  width: calc(100% - 300px);
  float: left;
  padding: 10px;
}
.mainContainer {
  margin: auto;
  width: 1005px;
  height: 1298px;
  border: black 1px solid;
  background-color: white;
}
.activity-section {
  height: 550px;
}
.photo-section {
  height: 280px;
  padding: 0;
}
.photo-section img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.blue-text {
  color: #003888;
}
.blue-bg {
  background-color: #003888;
}
.blue-border {
  border: #003888 1px solid !important;
}
</style>
